/** @jsx jsx */
import { jsx } from "theme-ui"
import GoblinExplorers from "../../../static/goblin-explorers.png"

const Image404 = () => {

  return (
    <img
      src={GoblinExplorers}
      alt="Goblin Explorers"
      style={{
        width: "100%"
      }}
    />
  )
}

export default Image404